import type { LocalePrefix, Pathnames } from "next-intl/routing"

export const EN = "en"
export const ZH_CN = "zh-CN"
export const ZH_TW = "zh-TW"

export const localeNames = {
    [EN]: "English", // 英语
    [ZH_CN]: "简体中文",
    [ZH_TW]: "繁体中文", // 繁体中文
    /*"cs": 'Čeština', // 捷克语
    "fr": 'Français', // 法语
    "de": 'Deutsch', // 德语
    "es": 'Español', // 西班牙语
    "it": 'Italiano', // 意大利语
    "ja": '日本語', // 日语
    "ko": '한국어', // 韩语
    "nl": 'Nederlands', // 荷兰语
    "pt-BR": 'Português do Brasil', // 巴西葡萄牙语
    "ru": 'Русский', // 俄语
    "uk": 'Українська', // 乌克兰语
    "vi": 'Tiếng Việt', // 越南语
   
    "pt": 'Português', // 葡萄牙语
    "da": 'Dansk', // 丹麦语
    "el": 'Ελληνικά (Elliniká)', // 希腊语
    "no": 'Norsk', // 挪威语
    "fi": 'Suomi', // 芬兰语
    "sv": 'Svenska', // 瑞典语
    "th": 'ไทย (Thai)', // 泰语
    "id": 'Bahasa Indonesia', // 印度尼西亚语
    "hi": 'हिन्दी (Hindi)', // 印地语
    // "ar": 'العربية (Arabic)', // 阿拉伯语 （从右往左）
    "bn": 'বাংলা (Bangla)', // 孟加拉语
    "ms": 'Bahasa Melayu', // 马来语
    "tr": 'Türkçe', // 土耳其语*/
    // "fa": 'فارسی (Farsi)', // 波斯语 （从右往左）
} as Record<string, string>

export const defaultLocale = "en" as const

// 所有语言列表的标识代码，不含具体语言名称
export const locales = Object.keys(localeNames)
// console.log("当前语言列表",locales)
// 完全依赖url的locale detection禁用cookie detection。避免用户在输入url时，因为cookie导致切换到cookie记录的语言
export const localeDetection = false as const
export const pathnames: Pathnames<typeof locales> = {
    "/feedback": {
        en: "/feedback",
        "zh-CN": "/反馈意见",
        "zh-TW": "/反饋意見",
    },
    "/tools/chinese-names-generator": {
        en: "/tools/chinese-names-generator",
        "zh-CN": "/tools/随机中文取名",
        "zh-TW": "/tools/隨機中文取名",
    },
    "/tools/chinese-names-generator/female": {
        en: "/tools/chinese-female-names-generator",
        "zh-CN": "/tools/随机取中文女性名",
        "zh-TW": "/tools/隨機取中文女性名",
    },
    "/tools/chinese-names-generator/male": {
        en: "/tools/chinese-male-names-generator",
        "zh-CN": "/tools/随机取中文男性名",
        "zh-TW": "/tools/隨機取中文男性名",
    },
    "/tools/chinese-names-generator/girl": {
        en: "/tools/chinese-girl-names-generator",
        "zh-CN": "/tools/随机取中文女孩名",
        "zh-TW": "/tools/隨機取中文女孩名",
    },
    "/tools/chinese-names-generator/boy": {
        en: "/tools/chinese-boy-names-generator",
        "zh-CN": "/tools/随机取中文男孩名",
        "zh-TW": "/tools/隨機取中文男孩名",
    },
    "/tools/chinese-names-generator/cat": {
        en: "/tools/chinese-cat-names-generator",
        "zh-CN": "/tools/随机取中文猫猫名",
        "zh-TW": "/tools/隨機取中文猫猫名",
    },
    "/tools/chinese-names-generator/cross-cultural": {
        en: "/tools/chinese-name-generator-from-english",
        "zh-CN": "/tools/英文名转中文名生成器",
        "zh-TW": "/tools/英文名轉中文名產生器",
    },
    "/tools/japanese-names-generator": {
        en: "/tools/japanese-names-generator",
        "zh-CN": "/tools/日本名字生成器",
        "zh-TW": "/tools/日本名字產生器",
    },
}
// as-needed means that the locale prefix is only added to the pathname if it is not already present.
export const localePrefix: LocalePrefix<typeof locales> = "as-needed"

export type I18nLocales = { key: string; name: string }[]

export const getCurrentLocaleName = (locale: string) => {
    return localeNames[locale]
}

// 生成多语言的alternates
export function alternatesLanguage(subPath: string) {
    const path = process.env.UE_WEB_URL
    const languages: Record<string, string> = {}
    locales.forEach((lang) => {
        languages[lang] =
            lang === defaultLocale ? `${path}${subPath}` : `${path}/${lang}${subPath}`
    })
    return languages
}

export function alternatesCanonical(locale: string, subPath: string, page?: string) {
    const path = process.env.UE_WEB_URL
    const withPages = page ? `/${page}` : ''
    return `${path}${defaultLocale === locale ? "" : `/${locale}`}${subPath}${withPages}`
}

export const port = process.env.PORT || 8000
export const host = process.env.UE_WEB_URL
    ? `https://${process.env.UE_WEB_URL}`
    : `http://localhost:${port}`
